/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React examples
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/DashboardNavbar';
import Footer from 'examples/Footer';
import { DateTimePicker } from '@mui/x-date-pickers';
import { pickersLayoutClasses } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import GradientLineChart from 'examples/Charts/LineCharts/GradientLineChart';
import { getGameSessions, getChartAmount, getStatistics, getEmailSummary } from 'services/analytics';
import { useEffect, useState } from 'react';
import { Card, Skeleton } from '@mui/material';
import HorizontalBarChart from 'examples/Charts/BarCharts/HorizontalBarChart';

function Dashboard() {
  const [gameWins, setGameWins] = useState([]);
  const [gameLoses, setGameLoses] = useState([]);
  const [correctMonths, setCorrectMonths] = useState([]);
  const [newRegistrations, setNewRegistrations] = useState([]);
  const [gameSessions, setGameSessions] = useState([]);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const [statistics, setStatistics] = useState([]);
  const [profit, setProfit] = useState(0);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [summaryFrom, setSummaryFrom] = useState();
  const [summaryTo, setSummaryTo] = useState();
  const [summary, setSummary] = useState({});

  const handleFromChange = (date, setterFrom, setterTo) => {
    if (to) {
      if (date > to) {
        setterFrom(to);
        setterTo(date);
      } else {
        setterFrom(date);
      }
    } else {
      setterFrom(new Date(date));
    }
  };
  const handleToChange = (date, setterFrom, setterTo) => {
    if (from) {
      if (date < from) {
        setterFrom(date);
        setterTo(from);
      } else {
        setterTo(date);
      }
    } else {
      setterTo(new Date(date));
    }
  };

  console.log(summaryFrom, summaryTo);

  useEffect(() => {
    getStatistics(from, to).then((res) => setStatistics(res.data));
  }, [from, to]);

  useEffect(() => {
    getEmailSummary(summaryFrom, summaryTo).then((res) => setSummary(res.data));
  }, [summaryFrom, summaryTo]);

  const gameData = {
    labels: correctMonths,
    datasets: [
      {
        label: 'Wins',
        data: gameWins,
        color: 'info'
      },
      {
        label: 'Losses',
        data: gameLoses
      }
    ]
  };

  const sessionsData = {
    labels: ['Daily bets'],
    datasets: [
      {
        label: 'Bets',
        data: gameSessions,
        color: 'info'
      }
    ]
  };

  useEffect(() => {
    getChartAmount('win').then((res) => {
      let wins = res.map((m) => {
        m[0] === 12
          ? setCorrectMonths((prev) => [...prev, months[0]])
          : setCorrectMonths((prev) => [...prev, months[m[0]]]);
        return m[1];
      });
      setGameWins(wins);
    });
    getChartAmount('lose').then((res) => {
      let loses = res.map((m) => {
        return m[1];
      });
      setGameLoses(loses);
    });
  }, []);

  useEffect(() => {
    getGameSessions().then((res) => setGameSessions(res));
    getStatistics().then((res) => setStatistics(res.data));
  }, []);

  useEffect(() => {
    let countProfit = 0;
    statistics?.profitForPeriod?.map((profit) => {
      countProfit = Number(countProfit) + Number(profit.profit);
    });
    setProfit(countProfit.toFixed(2));
  }, [statistics]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={5}>
          <Grid container spacing={3} direction='row' justify='center' alignItems='stretch'>
            <Grid item xs={8}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {gameWins.length > 0 ? (
                    <GradientLineChart
                      title='Game Wins vs. Losses ($)'
                      description='Monthly performance'
                      chart={gameData}
                      tension={0.5}
                    />
                  ) : (
                    <Skeleton height={400} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {gameSessions.length > 0 ? (
                    <HorizontalBarChart title='Daily bets' description='24 Hours performance' chart={sessionsData} />
                  ) : (
                    <Skeleton height={400} />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox position='relative' borderRadius='lg' p={3}>
                      <MDBox mb={1}>
                        <MDTypography
                          variant='h5'
                          fontWeight='bold'
                          textTransform='capitalize'
                          fontSize='1.2rem'
                          mb={2}
                        >
                          Statistics
                        </MDTypography>
                        <MDBox sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }} mb={2}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              label='From'
                              ampmInClock={false}
                              closeOnSelect={true}
                              ampm={false}
                              showDaysOutsideCurrentMonth
                              value={from}
                              viewRenderers={{
                                hours: null,
                                minutes: null,
                                seconds: null
                              }}
                              onChange={(e) => {
                                handleFromChange(e.$d, setFrom, setTo);
                              }}
                              slotProps={{
                                layout: {
                                  sx: {
                                    [`.${pickersLayoutClasses.actionBar}`]: {
                                      display: 'none'
                                    }
                                  }
                                }
                              }}
                            />
                            <DateTimePicker
                              label='To'
                              ampmInClock={false}
                              closeOnSelect={true}
                              ampm={false}
                              showDaysOutsideCurrentMonth
                              value={to}
                              viewRenderers={{
                                hours: null,
                                minutes: null,
                                seconds: null
                              }}
                              onChange={(e) => {
                                handleToChange(e.$d, setFrom, setTo);
                              }}
                              slotProps={{
                                layout: {
                                  sx: {
                                    [`.${pickersLayoutClasses.actionBar}`]: {
                                      display: 'none'
                                    }
                                  }
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </MDBox>
                        <MDTypography fontSize='13px'>
                          {from || to
                            ? `Showing results from ${
                                from ? new Date(from).toLocaleDateString() : 'the beggining'
                              } to ${to ? new Date(to).toLocaleDateString() : 'now'}`
                            : 'Showing results for all the time'}{' '}
                        </MDTypography>
                      </MDBox>
                      <MDBox>
                        <MDBox sx={{ display: 'flex', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Bets:
                          </MDTypography>
                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            {` ${statistics?.betsForPeriod ? statistics?.betsForPeriod : 0}`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDBox sx={{ display: 'flex', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Bets (Amount):
                          </MDTypography>
                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            {` ${
                              statistics?.betsForPeriodAmount?.length > 0
                                ? statistics?.betsForPeriodAmount[0]?.amount
                                  ? statistics?.betsForPeriodAmount[0]?.amount
                                  : 0
                                : statistics?.betsForPeriodAmount?.amount
                            }`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDBox sx={{ display: 'flex', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Non-rollbacked bets:
                          </MDTypography>
                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            {` ${statistics?.nonRollbackedBetsForPeriod ? statistics?.nonRollbackedBetsForPeriod : 0}`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDBox sx={{ display: 'flex', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Non-rollbacked bets (Amount):
                          </MDTypography>

                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            {` ${
                              statistics?.nonRollbackedBetsForPeriodAmount?.length > 0
                                ? statistics?.nonRollbackedBetsForPeriodAmount[0]?.amount
                                  ? statistics?.nonRollbackedBetsForPeriodAmount[0]?.amount
                                  : 0
                                : statistics?.nonRollbackedBetsForPeriodAmount?.amount
                            }`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox>
                        <MDBox sx={{ display: 'flex', gap: '5px' }}>
                          <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                            Profit:
                          </MDTypography>
                          <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                            {` ${profit}`}
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <MDBox position='relative' borderRadius='lg' p={3}>
                      <MDBox mb={1}>
                        <MDTypography
                          variant='h5'
                          fontWeight='bold'
                          textTransform='capitalize'
                          fontSize='1.2rem'
                          mb={2}
                        >
                          Email Metric Summary
                        </MDTypography>
                        <MDBox sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }} mb={2}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              label='From'
                              ampmInClock={false}
                              closeOnSelect={true}
                              ampm={false}
                              showDaysOutsideCurrentMonth
                              value={summaryFrom}
                              viewRenderers={{
                                hours: null,
                                minutes: null,
                                seconds: null
                              }}
                              onChange={(e) => {
                                handleFromChange(new Date(e.$d).toDateString(), setSummaryFrom, setSummaryTo);
                              }}
                              slotProps={{
                                layout: {
                                  sx: {
                                    [`.${pickersLayoutClasses.actionBar}`]: {
                                      display: 'none'
                                    }
                                  }
                                }
                              }}
                            />
                            <DateTimePicker
                              label='To'
                              ampmInClock={false}
                              closeOnSelect={true}
                              ampm={false}
                              showDaysOutsideCurrentMonth
                              value={summaryTo}
                              viewRenderers={{
                                hours: null,
                                minutes: null,
                                seconds: null
                              }}
                              onChange={(e) => {
                                handleToChange(new Date(e.$d).toDateString(), setSummaryFrom, setSummaryTo);
                              }}
                              slotProps={{
                                layout: {
                                  sx: {
                                    [`.${pickersLayoutClasses.actionBar}`]: {
                                      display: 'none'
                                    }
                                  }
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </MDBox>
                        <MDTypography fontSize='13px'>
                          {summaryFrom || summaryTo
                            ? `Showing results from ${
                                summaryFrom ? new Date(summaryFrom).toLocaleDateString() : 'the beggining'
                              } to ${summaryTo ? new Date(summaryTo).toLocaleDateString() : 'now'}`
                            : 'Showing results for all the time'}{' '}
                        </MDTypography>
                      </MDBox>
                      {Object?.entries(summary)?.map((game, index) => {
                        return (
                          <MDBox
                            mb={index + 1 !== Object?.entries(summary).length && 2}
                            sx={{ border: '0.3px solid white', borderRadius: '12px', padding: '15px' }}
                          >
                            <MDTypography
                              sx={{
                                fontWeight: 800,
                                borderBottom: '1px solid white',
                                width: '100%'
                              }}
                            >
                              {game[0]}
                            </MDTypography>
                            <MDBox mt={2}>
                              <MDBox sx={{ display: 'flex', gap: '5px' }}>
                                <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                  Player Count:
                                </MDTypography>
                                <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                  {` ${game[1]?.playerCount ? game[1]?.playerCount : 0}`}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                            <MDBox>
                              <MDBox sx={{ display: 'flex', gap: '5px' }}>
                                <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                  Bets:
                                </MDTypography>
                                <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                  {` ${game[1]?.betCount ? game[1]?.betCount : 0}`}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                            <MDBox>
                              <MDBox sx={{ display: 'flex', gap: '5px' }}>
                                <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                  Bet Average:
                                </MDTypography>
                                <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                  {` ${game[1]?.betAverage ? game[1]?.betAverage : 0}`}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                            <MDBox>
                              <MDBox sx={{ display: 'flex', gap: '5px' }}>
                                <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                  Bets (Amount):
                                </MDTypography>
                                <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                  {` ${game[1]?.betSum ? game[1]?.betSum : 0}`}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                            <MDBox>
                              <MDBox sx={{ display: 'flex', gap: '5px' }}>
                                <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                  Non-rollbacked bets:
                                </MDTypography>
                                <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                  {` ${game[1]?.nonRolledBackBetsCount ? game[1]?.nonRolledBackBetsCount : 0}`}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                            <MDBox>
                              <MDBox sx={{ display: 'flex', gap: '5px' }}>
                                <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                  Non-rollbacked bets (Amount):
                                </MDTypography>
                                <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                  {` ${game[1]?.nonRollbackedBetsAmount ? game[1]?.nonRollbackedBetsAmount : 0}`}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                            <MDBox>
                              <MDBox sx={{ display: 'flex', gap: '5px' }}>
                                <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                  Percent Rolled Back Bets:
                                </MDTypography>
                                <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                  {` ${game[1]?.percentRolledBackBets ? game[1]?.percentRolledBackBets.toFixed(2) : 0}`}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                            <MDBox>
                              <MDBox sx={{ display: 'flex', gap: '5px' }}>
                                <MDTypography variant='text' fontWeight='medium' fontSize='1rem'>
                                  Average Rolled Back Bets (Amount):
                                </MDTypography>
                                <MDTypography variant='text' fontWeight='normal' fontSize='1rem'>
                                  {` ${
                                    game[1]?.averageRollbackedBetsAmount ? game[1]?.averageRollbackedBetsAmount : 0
                                  }`}
                                </MDTypography>
                              </MDBox>
                            </MDBox>
                          </MDBox>
                        );
                      })}
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
