const sessionsColumnData = [
  {
    Header: 'Id',
    accessor: 'id'
  },
  // {
  //   Header: 'Player',
  //   accessor: 'player'
  // },
  {
    Header: 'Player Nickname',
    accessor: 'playerNickname',
    Cell: ({ value }) => {
      return value ? value : '-';
    }
  },
  {
    Header: 'Player username',
    accessor: 'playerUsername'
  },
  {
    Header: 'Game mode',
    accessor: 'gameMode'
  },
  {
    Header: 'Game',
    accessor: 'game'
  },
  {
    Header: 'Bet range',
    accessor: 'betRange',
    Cell: ({ value }) => {
      return value?.minBet !== null && value?.maxBet !== null
        ? `${value.min} - ${value.max}`
        : value?.singleBet !== null
        ? value.singleBet
        : '-';
    }
  },
  {
    Header: 'Duration',
    accessor: 'duration'
  },
  {
    Header: 'IP',
    accessor: 'ip',
    Cell: ({ value }) => {
      return value ? value : '-';
    }
  },
    {
    Header: 'Session Date',
    accessor: 'sessionDate'
  },
  {
    Header: 'Country',
    accessor: 'sessionCountry',
    Cell: ({ value }) => {
      return value ? value : '-';
    }
  }
];
export default sessionsColumnData;
